<template lang="pug">
form(@submit.prevent="submitForm")
  #error_explanation.red-text(v-if="errors.length > 0")
    ul
      li(v-for="error in errors" :key="error") {{ error }}
  .row
    .col.s12
      .form-inputs
        label(for="limit_id") Bet Type
        select(v-model="formData.bet_type" class="browser-default")
          option(v-for="bet_type in JSON.parse(bet_types)" :key="bet_type" :value="bet_type") {{ bet_type }}
  .row
    .col.s12
      .form-inputs
        label(for="limit_id") Bet Limit
        select(v-model="formData.limit_type" class="browser-default")
          option(v-for="limit_type in JSON.parse(limit_types)" :key="limit_type" :value="limit_type") {{ limit_type }}
  .row
    .col.s12
      .form-inputs
        label(for="bet_limitable_type") Bet Limitable Type
        select(v-model="formData.bet_limitable_type" class="browser-default" @change="selectLimitableType")
          option(v-for="limitable_type in JSON.parse(bet_limitable_types)" :key="limitable_type") {{ limitable_type }}
  .row
    .col.s12
      .form-inputs
        label(for="categories") Category
        select(v-model="formData.category_id" class="browser-default" @change="selectCategory")
          option(v-for="category in categories" :key="category.id" :value="category.id") {{ category.name }}
  .row(v-if="formData.bet_limitable_type !== 'Category'")
    .col.s12
      .form-inputs
        label(for="events") Event
        select(v-model="formData.event_id" class="browser-default" @change="selectEvent")
          option(v-for="event in events" :key="event.id" :value="event.id") {{ event.name }} {{ event.action_date }}
  .row(v-if="formData.bet_limitable_type === 'Market' || formData.bet_limitable_type === 'Competitor'")
    .col.s12
      .form-inputs
        label(for="market") Market
        select(v-model="formData.market_id" class="browser-default" @change="selectMarket")
          option(v-for="market in markets" :key="market.id" :value="market.id") {{ market.name }} {{ moment(market.scheduled_off_time).format('DD/MM/YY h:mm') }}
  .row(v-if="formData.bet_limitable_type === 'Competitor'")
    .col.s12
      .form-inputs
        label(for="competitor") Competitor
        select(v-model="formData.competitor_id" class="browser-default")
          option(v-for="competitor in competitors" :key="competitor.id" :value="competitor.id") {{ competitor.name }}
  .row
    .input-field.col.s12
      label Amount
      input(type="number" v-model="formData.amount")
  br
  br
  .row
    .col.s12
      .form-inputs
        button(type="submit" class="btn btn-block") Save
  br
  br
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import axios_client from '../utils/axios_client';
import moment from 'moment';

export default {
  props: {
    user_id: {
      type: String,
      required: true,
    },
    bet_types: {
      type: String,
      required: true,
    },
    limit_types: {
      type: String,
      required: true,
    },
    bet_limitable_types: {
      type: String,
      required: true,
    },
    bet_limit: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const formData = reactive({
      bet_type: 'single',
      limit_type: 'unit_stake',
      bet_limitable_type: 'Category',
      bet_limitable_id: '',
      category_id: 0,
      event_id: null,
      market_id: null,
      competitor_id: null,
      amount: 0,
    });

    const errors = ref([]);
    const categories = ref([]);
    const events = ref([]);
    const markets = ref([]);
    const competitors = ref([]);

    const fetchCategories = async () => {
      const response = await axios_client.get('/categories.json');
      categories.value = response.data;
    };

    const fetchEvents = async (categoryId) => {
      const response = await axios_client.get(`/categories/${categoryId}/events.json`);
      events.value = response.data;
    };

    const fetchMarkets = async (eventId) => {
      const response = await axios_client.get(`/events/${eventId}/markets.json`);
      markets.value = response.data;
    };

    const fetchCompetitors = async (eventId, marketId) => {
      const response = await axios_client.get(`/events/${eventId}/markets/${marketId}/competitors.json`);
      competitors.value = response.data;
    };

    const selectLimitableType = () => {
      if (formData.bet_limitable_type === 'Category') {
        if (categories.value.length === 0) {
          fetchCategories();
        }
      }

      selectCategory();
      selectEvent();
      selectMarket();
    };

    const selectCategory = () => {
      if (formData.bet_limitable_type !== 'Category' && formData.category_id !== null) {
        fetchEvents(formData.category_id);
      }
    };

    const selectEvent = () => {
      if ((formData.bet_limitable_type === 'Market' || formData.bet_limitable_type === 'Competitor') && formData.event_id !== null) {
        fetchMarkets(formData.event_id);
      }
    };

    const selectMarket = () => {
      if (formData.bet_limitable_type === 'Competitor' && formData.event_id !== null && formData.market_id !== null) {
        fetchCompetitors(formData.event_id, formData.market_id);
      }
    };

    const submitForm = async () => {
      switch (formData.bet_limitable_type) {
        case 'Category':
          formData.bet_limitable_id = formData.category_id;
          break;
        case 'Event':
          formData.bet_limitable_id = formData.event_id;
          break;
        case 'Market':
          formData.bet_limitable_id = formData.market_id;
          break;
        case 'Competitor':
          formData.bet_limitable_id = formData.competitor_id;
          break;
      }

      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      try {
        let response;
        if (!props.bet_limit) {
          response = await axios_client.post(`/users/${props.user_id}/bet-limits.json`,
            { bet_limit: formData },
            { headers: { 'X-CSRF-Token': csrfToken } });
        } else {
          response = await axios_client.put(`/users/${props.user_id}/bet-limits/${formData.id}.json`,
            { bet_limit: formData },
            { headers: { 'X-CSRF-Token': csrfToken } });
        }
        window.location.href = `/users/${props.user_id}/bet-limits/${response.data.id}`;
      } catch (error) {
        errors.value = error.response.data.messages;
      }
    };

    onMounted(() => {
      fetchCategories();
      if (props.bet_limit) {
        Object.assign(formData, JSON.parse(props.bet_limit));
        selectCategory();
        selectEvent();
        selectMarket();
      }
    });

    return {
      formData,
      errors,
      categories,
      events,
      markets,
      competitors,
      fetchCategories,
      fetchEvents,
      fetchMarkets,
      fetchCompetitors,
      selectLimitableType,
      selectCategory,
      selectEvent,
      selectMarket,
      submitForm,
      moment
    };
  }
};
</script>