<template lang="pug">
form(@submit.prevent="submitForm")
  .row.mt-3
    .input-field.col.s12
      b Bet Type
      select(v-model="betLimit.bet_type" class="browser-default")
        option(v-for="betType in betTypes" :key="betType" :value="betType") {{ betType }}
  .row.mt-3
    .input-field.col.s12
      b Bet Limit
      select(v-model="betLimit.limit_type" class="browser-default")
        option(v-for="limitType in limitTypes" :key="limitType" :value="limitType") {{ limitType }}
  .row.mt-3
    .input-field.col.s12
      b Bet Limitable Type
      select(v-model="betLimit.bet_limitable_type" class="browser-default")
        option(v-for="betLimitableType in betLimitableTypes" :key="betLimitableType" :value="betLimitableType") {{ betLimitableType }}
  .row.mt-3
    .input-field.col.s12
      b Category Selector
      span &nbsp;
      i.red-text(v-if="betLimit.category === null") Please select a category
      select(v-model="betLimit.category" class="browser-default" @change="updateSelectableEvents")
        option(v-for="category in categories" :key="category.id" :value="category") {{ category.name }}
  .row.mt-3
    .input-field.col.s12(v-if="showEventSelector")
      b Event Selector
      span &nbsp;
      i.red-text(v-if="selectedEvent === null") Please select an event
      select(v-model="selectedEvent" class="browser-default" @change="updateSelectableMarkets")
        option(v-for="event in selectableEvents" :key="event.id" :value="event") {{ event.name }} {{ event.action_date }}
  .row.mt-3
    .input-field.col.s12(v-if="showMarketSelector")
      b Market Selector
      span &nbsp;
      i.red-text(v-if="selectedMarket === null") Please select a market
      select(v-model="selectedMarket" class="browser-default" @change="updateSelectableCompetitors")
        option(v-for="market in selectableMarkets" :key="market.id" :value="market")
          span {{ market.name }}
          span &nbsp;
          span {{ moment(market.scheduled_off_time).format('MMMM Do YYYY, HH:mm') }}
  .row.mt-3
    .input-field.col.s12(v-if="showCompetitorSelector")
      b Competitor Selector
      span &nbsp;
      i.red-text(v-if="selectedCompetitor === null") Please select a competitor
      select(v-model="selectedCompetitor" class="browser-default" @change="updateSelectableCompetitors")
        option(v-for="competitor in selectableCompetitors" :key="competitor.id" :value="competitor")
          span {{ competitor.card_number }}
          span &nbsp;
          span {{ competitor.name }}
          span &nbsp;
          span {{ competitor.latest_price.fractional }}
  .row.mt-3
    .input-field.col.s12
      b Amount
      input(type="number" v-model="betLimit.amount")
  .row
    .col.s12
      button.btn.btn-block(type="submit" :disabled="!submitable") Submit
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios_client from '../../utils/axios_client';
import flashMessage from '../../utils/flash_message';
import moment from 'moment';

export default {
  setup() {
    const betLimit = ref(window.betLimit);
    const betTypes = ref(window.betTypes);
    const limitTypes = ref(window.limitTypes);
    const betLimitableTypes = ref(window.betLimitableTypes);
    const categories = ref(window.categories);
    const selectableEvents = ref([]);
    const selectedEvent = ref(null);
    const selectableMarkets = ref([]);
    const selectedMarket = ref(null);
    const selectableCompetitors = ref([]);
    const selectedCompetitor = ref(null);

    const showEventSelector = computed(() => {
      const betLimitableTypes = ['Event', 'Market', 'Competitor'];
      return betLimitableTypes.includes(betLimit.value.bet_limitable_type) && selectableEvents.value.length > 0;
    });

    const showMarketSelector = computed(() => {
      const betLimitableTypes = ['Market', 'Competitor'];
      return betLimitableTypes.includes(betLimit.value.bet_limitable_type) && selectableMarkets.value.length > 0;
    });

    const showCompetitorSelector = computed(() => {
      const betLimitableTypes = ['Competitor'];
      return betLimitableTypes.includes(betLimit.value.bet_limitable_type) && selectableCompetitors.value.length > 0;
    });

    const submitable = computed(() => {
      if (betLimit.value.bet_type === null || betLimit.value.bet_type === '') {
        return false;
      }
      if (betLimit.value.limit_type === null || betLimit.value.limit_type === '') {
        return false;
      }
      if (betLimit.value.bet_limitable_type === null || betLimit.value.bet_limitable_type === '') {
        return false;
      }
      if (betLimit.value.amount === null || betLimit.value.amount === 0) {
        return false;
      }
      if (betLimit.value.bet_limitable_type === 'Category' && betLimit.value.category === null) {
        return false;
      }
      if (betLimit.value.bet_limitable_type === 'Event' && selectedEvent.value === null) {
        return false;
      }
      if (betLimit.value.bet_limitable_type === 'Market' && selectedMarket.value === null) {
        return false;
      }
      if (betLimit.value.bet_limitable_type === 'Competitor' && selectedCompetitor.value === null) {
        return false;
      }
      return true;
    });

    const updateSelectableEvents = () => {
      axios_client.get(`/categories/${betLimit.value.category.id}/events.json`)
        .then(response => {
          selectableEvents.value = response.data;
        });
    };

    const updateSelectableMarkets = () => {
      axios_client.get(`/events/${selectedEvent.value.id}/markets.json`)
        .then(response => {
          selectableMarkets.value = response.data;
        });
    };

    const updateSelectableCompetitors = () => {
      axios_client.get(`/events/${selectedEvent.value.id}/markets/${selectedMarket.value.id}.json`)
        .then(response => {
          selectableCompetitors.value = response.data.competitors;
        });
    };

    const submitForm = () => {
      const betLimitables = [
        ['Competitor', selectedCompetitor.value],
        ['Market', selectedMarket.value],
        ['Event', selectedEvent.value],
        ['Category', betLimit.value.category]
      ];

      const betLimitable = betLimitables.find(betLimitable => betLimitable[0] === betLimit.value.bet_limitable_type);
      const betLimitableId = betLimitable[1].id;

      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      axios_client.defaults.headers.common['X-CSRF-Token'] = csrfToken;

      const betLimitParams = {
        bet_type: betLimit.value.bet_type,
        limit_type: betLimit.value.limit_type,
        bet_limitable_type: betLimit.value.bet_limitable_type,
        bet_limitable_id: betLimitableId,
        amount: betLimit.value.amount
      };

      axios_client.post(`/users/${betLimit.value.user_id}/bet-limits.json`, { bet_limit: betLimitParams }, { headers: { 'X-CSRF-Token': csrfToken } })
        .then(response => window.location.href = response.headers.location)
        .catch(error => {
          flashMessage({}, { message: error.response.data.join(', '), class: 'red' });
        });
    };

    onMounted(() => {
      // Initialization code if needed
    });

    return {
      betLimit,
      betTypes,
      limitTypes,
      betLimitableTypes,
      categories,
      selectableEvents,
      selectedEvent,
      selectableMarkets,
      selectedMarket,
      selectableCompetitors,
      selectedCompetitor,
      showEventSelector,
      showMarketSelector,
      showCompetitorSelector,
      submitable,
      submitForm,
      updateSelectableEvents,
      updateSelectableMarkets,
      updateSelectableCompetitors,
      moment,
    };
  }
};
</script>
