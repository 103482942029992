import { createApp } from 'vue';
import App from './App.vue';

const app = createApp(App);

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('bet-limits');
  if (el) {
    app.mount(el);
  }
});